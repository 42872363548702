.cloud {
  z-index: -2;
  background: #fff;
  background: linear-gradient(to bottom, #fff 5%, #f1f1f1 100%);
  border-radius: 10vh;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  height: 12vh;
  position: absolute;
  width: 35vh;
}

.cloud:after,
.cloud:before {
  background: #fff;
  content: '';
  position: absolute;
  z-index: -1;
}

.cloud:after {
  border-radius: 10vh;
  height: 10vh;
  left: 5vh;
  top: -5vh;
  width: 10vh;
}

.cloud:before {
  border-radius: 20vh;
  width: 18vh;
  height: 18vh;
  right: 5vh;
  top: -9vh;
}
