html,
body {
  width: 100%;
  height: 100%;
}

body {
  text-align: center;
  background: linear-gradient(#38a3d1, #90dffe);
  text-shadow: 3px 3px 5px #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  color: #fff;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

button:active {
  border: none;
}

button:focus {
  outline: 0;
}

@keyframes animateCloud {
  0% {
    margin-left: -100vw;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes wobbleReverse {
  0% {
    transform: translateX(0%) scale(-1, 1);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg) scale(-1, 1);
  }
  30% {
    transform: translateX(20%) rotate(3deg) scale(-1, 1);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg) scale(-1, 1);
  }
  60% {
    transform: translateX(10%) rotate(2deg) scale(-1, 1);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg) scale(-1, 1);
  }
  100% {
    transform: translateX(0%) scale(-1, 1);
  }
}
